@font-face {
  font-family: 'PPNeueMontreal';
  src: url('./fonts/pp-neue-montreal/PPNeueMontreal-Medium.otf')
    format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
    url('./fonts/poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: local('Poppins'),
    url('./fonts/poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Just Me Again Down Here';
  src: local('Just Me Again Down Here'),
    url('./fonts/JustMeAgainDownHere-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Kolker Brush';
  src: local('Kolker Brush'),
    url('./fonts/KolkerBrush-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: local('La Belle Aurore'),
    url('./fonts/LaBelleAurore-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Morguns';
  src: local('Morguns'), url('./fonts/Morguns-Condensed.ttf') format('truetype');
}

html {
  background-color: #f8f8f8;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border: 8px solid transparent;
  border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: 'transparent';
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  width: 10px !important;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

[aria-invalid='true'] {
  box-shadow: none !important;
}

.chakra-radio__control[data-checked] {
  background: unset !important;
  background-color: black !important;
}

.chakra-switch__thumb {
  background: #7bff66 !important;
}

body {
  background-color: white !important;
}

option {
  background: white !important;
  color: #323d61 !important;
}
select {
  color: #323d61 !important;
}

/* This should be the date input */
[name*='date'][aria-haspopup='dialog'] {
  border-color: black !important;
  color: black !important;
}
